<template>
  <div></div>
</template>
<script>
import {
 getBuyUrl
} from "../../utils/api";
export default {
  data() {
    return {};
  },
  created() {
     this.getUrl()
    
  },
  methods: {
      async getUrl() {
        let newarry = []
        for(let key in this.$route.query) {
            if(key=="shopId") {
                newarry.push({
                    [key]: this.$route.query[key]
                })
            }else {
                newarry.push({
                    source: this.$route.query[key]
                })
            }
            
        }
        let data = {
            source: newarry[0].source,
            shopId: newarry.length>1?newarry[1].shopId?newarry[1].shopId:localStorage.shopMsg?JSON.parse(localStorage.shopMsg).id:0:localStorage.shopMsg?JSON.parse(localStorage.shopMsg).id:0
        }
        let res = await getBuyUrl(data)
        if(res.code==1) {
            window.location.replace(res.data)
            // console.log(window.history)
        }
      }
  }
};
</script>